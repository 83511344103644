import { Component, OnInit, Input, OnDestroy, ViewChild, ChangeDetectorRef, HostListener, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'underscore';
import { Router, ActivatedRoute } from '@angular/router';
import { ExportService } from '../_services/export.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SharedServiceService } from 'app/_services/shared-service.service';
declare var $: any;
import * as moment from 'moment';

// declare let jsPDF; 

@Component({
  selector: 'app-wms-oders',
  templateUrl: './wms-oders.component.html',
  styleUrls: ['./wms-oders.component.css'],
  changeDetection: ChangeDetectionStrategy.Default

})
export class WmsOdersComponent implements OnInit {
  filterOrders = "";
  isButtonEnable: boolean;
  pos: string;
  post: string;
  dynamicorderDetailsByCheckBox: any[];
  dict = {};
  selectedInfoForView: any;
  selectedInfoForEdit: any;
  finalData: { order_id: string; order_date: string; customer_name: string; payment_status: string; order_type: string; status: string; }[];
  selectedAlldata = [];
  selectedAlldataArray = [];
  allOrdersList: any;
  loader: boolean = false;
  message_display: boolean = false;
  showThisScreenWithPermissions: any;
  permissionForOnlyThisScreen = [];
  allPermissionForOnlyThisScreen: any;
  custome_download: FormGroup;
  custome_search: FormGroup;
  // submitted: boolean;
  from_date: any;
  to_date: any;
  customeData: any;
  pageOfItems: any;
  first_id: any;
  last_id: any;
  totalElements: any;
  forFilterUse: any;
  fullfillData: any;
  config: any;
  pager: any = {};
  pagedItems = [];
  finalStatus: any;
  status_with_time: any;
  statusTimeStampArray = [];
  finalArray: any;
  modifiedStatusArray: any;
  statusArray = [];
  updateMesg: any;
  NewStatusArray = [];
  hidethisStatus: boolean;
  NewStatusArray_final = [];
  statusArray_main_one_userMatrix = [];
  currectArray = [];
  statusArray_main_one_userMatrix_include_key = [];
  thisIsMatched: boolean;
  arraychecking = [];
  sendThis_item_s: any;
  sendThis_selected_previous_status: any;
  showThisSelectMSG: boolean;
  showOnlyForCancel: boolean;
  showThisMsgReasonRequired: boolean;
  pageCount = [];
  howmanySelectedArray = [];
  ShareThisSelectedList: number = 0;
  onlyForDashboard: any;
  sortDir = 1; //1= 'ASE' -1= DSC
  testingNow: any;
  shareThisCount: any = 20;
  offsetVal: number = 0;
  totalOrders: any;
  public isFliterActive: boolean = false;
  public bulk_update_waiting: boolean = false;
  allStoresCode:any;
  allStoresIds:any;
  to_time: any;
  from_time: any;
  public moment: any = moment;


  constructor(private ref: ChangeDetectorRef, private router: Router, private formBuilder: FormBuilder, private exportService: ExportService, private service: SharedServiceService) {
    this.onlyForDashboard = localStorage.getItem('dashboard');
    console.log(this.onlyForDashboard);
    this.getAllOrdersList("pageLoad");
    this.showThisScreenWithPermissions = JSON.parse(localStorage.getItem('screensList'));
    // console.log(this.showThisScreenWithPermissions);

    for (var i = 0; i < this.showThisScreenWithPermissions.screens.length; i++) {
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Orders") {
        var forOrders = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreen.push(forOrders);
      }
    }
    // console.log(this.permissionForOnlyThisScreen[0]);
    this.allPermissionForOnlyThisScreen = this.permissionForOnlyThisScreen[0];
    console.log(this.allPermissionForOnlyThisScreen);

    this.custome_download = this.formBuilder.group({
      action:["manual_batch_for_dateRange"],
      from_date: [ ],
      to_date: [ ],
      status_id: [''],
      from_time: [ ],
      to_time: [ ],
      store_code: ['default', Validators.required]

    });

    this.custome_search = this.formBuilder.group({
      order_id: [''],
      order_date: [''],
      customer_name: [''],
      payment_type: [''],
      order_status: ['']
    });
  }


  get a() { return this.custome_download.controls; }
  get b() { return this.custome_search.controls; }

  public selectedStatusInfor(v) {
    // console.log(v);
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");

    $("#order_id_filter").attr("readonly", "true");
    $("#order_date").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");

  }
  downloadCustomerOrder(data){
    this.service.downloadCustomeWmsorders(data).subscribe((res:any) => {
      console.log(res);
      // document.getElementById("order_success_popup").click()
      this.loader = false;
      if (res === null) {
        $.notify({
          icon: "add_alert",
          message: "Orders unable to push to WMS!"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      } else {
        this.customeData = res;
        console.log(this.customeData)
        $.notify({
          icon: "add_alert",
          message: res.message || "success"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
        document.getElementById("order_success_popup").click();
        this.exportService.exportExcel(this.customeData[0], 'Order Export');
        $('#from_date').val();
        $('#to_date').val();
        $('#selectedStatus').val("");
        // this.submitted = false;
        this.custome_download.reset();
      }
    }, err => {
      // console.log(err);
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: err.error.error_desc
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    });
  }

  runCronWms(){
    let data={
      "action": "run_cron"
  }
  this.downloadCustomerOrder(data);
  }
  
  pushOrdersWms(){
    let order_ids = [];
    console.log("orderList",this.orderList);
    this.orderList.forEach((item:any)=>{
      order_ids.push(item.order_id);
    })
    console.log("order_ids1",order_ids);
    let data={
      "action": "manual_batch_for_orders",
      "order_id":order_ids
    }
  this.downloadCustomerOrder(data);
  }

  submit(){
    // this.loader=true;
     if(this.a.from_time.value && this.a.to_time.value){
        if(this.a.from_date.value && this.a.to_date.value){
          //method call
          this.customeExport();
        }else{
          this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "Please Fill From Date and To Dates"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
  }
     }else if(this.a.from_time.value || this.a.to_time.value){
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "Please Fill Time and Date"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
     }else{
      if(this.a.from_date.value && this.a.to_date.value){
        // maethod call
        this.customeExport();
      }else if(this.a.from_date.value || this.a.to_date.value){
        alert('please fill two to dates')
      }else{
        //method call
        this.customeExport();
      }
     }

  }

  public customeExport() {
    this.loader = true;
    // this.submitted = true;
    if (this.custome_download.invalid) {
      this.loader = false;
      return;
    }
    // console.log(this.custome_download.value);

    this.from_date = new Date(this.a.from_date.value);
    this.to_date = new Date(this.a.to_date.value);
    // console.log(this.from_date, this.to_date);
    // console.log(this.to_date - this.from_date);
    // this.downloadCustomerOrder(this.custome_download.value);
    // return
    if ((this.to_date - this.from_date) >= 0) {
      console.log(this.custome_download.value);
      this.downloadCustomerOrder(this.custome_download.value);
     
    } else if(this.to_date < this.from_date){
      $('#to_date').val("");
      // console.log("to_date");
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "To Date Should be Gater then From Date! Please currect the Date and try again"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }else if(this.from_date){
      $('#to_date').val("");

      // console.log("to_date");
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "Please Select Date"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }
    // else{
    //   $('#to_date').val("");
    //   // console.log("to_date");
    //   this.loader = false;
    //   $.notify({
    //     icon: "add_alert",
    //     message: "Please Enter From-Date and To-Date"
    //   }, {
    //     type: 'info',
    //     timer: 1000,
    //     placement: {
    //       from: 'top',
    //       align: 'center'
    //     }
    //   });
    // }
  }
  customeDownloads() {
    $('#from_date').val("");
    $('#to_date').val("");
    $('#selectedStatus').val("");
    // this.submitted = false;
    this.custome_download.reset();
  }
  public reSet() {
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");

    $("#order_id_filter").attr("readonly", "true");
    $("#order_date").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");
    // $('#status_filter').attr('disabled', true);
    this.offsetVal = 0;
    this.shareThisCount = 10;
    this.isFliterActive = false;
    this.getAllOrdersList("pageLoad");
    let closeFilter = document.getElementById("collapse2");
    if (closeFilter.classList.contains('show')) {
      closeFilter.classList.remove('show');
    }
    // $('.collapsed').trigger('click');
    // $('#heading1').click();
  }
  enableThis1() {
    document.getElementById('order_id_filter').removeAttribute('readonly');
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");

    $("#order_date").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");
    // $('#status_filter').attr('disabled', true);

  }
  enableThis2() {
    document.getElementById('order_date').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");

    $("#order_id_filter").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");
    // $('#status_filter').attr('disabled', true);

  }
  enableThis3() {
    document.getElementById('customer_name').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");

    $("#order_id_filter").attr("readonly", "true");
    $("#order_date").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");
    // $('#status_filter').attr('disabled', true);

  }
  enableThis4() {
    document.getElementById('payment_type').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#status_filter').val("");

    $("#order_id_filter").attr("readonly", "true");
    $("#order_date").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");
    // $('#status_filter').attr('disabled', true);

  }
  all(data) {

    console.log(data);
    this.selectedAlldata = data;
    console.log(this.selectedAlldata);
    this.orderList=this.selectedAlldata
    if ($('#selectall').prop("checked") == true) {
      $('body').on('click', '#selectall', function () {
        $('.singlechkbox').prop('checked', this.checked);
      });
      console.log("Checkbox is checked.");
      this.ShareThisSelectedList = this.pagedItems.length;
    }
    else if ($('#selectall').prop("checked") == false) {
      console.log("Checkbox is unchecked.");
      this.selectedAlldata = [];
      this.ShareThisSelectedList = 0;
      // console.log(this.selectedAlldata);
    }
  }
  generate() {
    console.log(this.ShareThisSelectedList);
    if (this.ShareThisSelectedList > 0) {
      console.log(this.selectedAlldata);
      // var finalData = _.map(this.selectedAlldata, function (item) {
      //   return _.pick(item, "order_no", "created_at", "first_name", "last_name", "payment_method", "status")
      // })
      // finalData = finalData.filter((item) => {
      //   return item != null;
      // });
      // console.log(finalData);  
      var finalDataModified = [];
      for (var i = 0; i < this.pagedItems.length; i++) {
        if ($('#dynamicID' + i).prop("checked") === true) {
          var use = {
            "Order No": this.pagedItems[i].order_no,
            "Created At": this.pagedItems[i].created_at,
            "First Name": this.pagedItems[i].first_name,
            "Last Name": this.pagedItems[i].last_name,
            "Payment Method": this.pagedItems[i].payment_method,
            "Status": this.pagedItems[i].status
          }
          finalDataModified.push(use);
        }
      }
      this.exportService.exportExcel(finalDataModified, 'Order Export');
      $('#selectall').prop("checked", false);
      this.getAllOrdersList();
    } else {
      $.notify({
        icon: "add_alert",
        message: "Please select the List"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }
  }
  getInputDateFormat(date) {
    return date.toISOString().split('T')[0];
  }

  validDate() {
    var today = new Date();
    var maxDate = new Date();

    document.getElementsByName("from_date_dd")[0].setAttribute('max', this.getInputDateFormat(today));
    document.getElementsByName('to_date_dd')[0].setAttribute('max', this.getInputDateFormat(maxDate));
  }
  ngOnInit(): void {
    this.getStoreCode();
    this.pageCount = [
      { "count": 10 },
      { "count": 20 },
      { "count": 50 },
      { "count": 100 },
      { "count": 500 },
    ]
  }

  public getAllOrdersList(call = null) {
    this.loader = true;
    console.log(localStorage.getItem('forCRMTEAM'))
    let requestBody = {
      'user_id': localStorage.getItem('role_id'),
      'offset': this.offsetVal,
      'limit': this.shareThisCount,
      'is_payment_failed': false
    }

    this.service.getAllWmsOrders(requestBody).subscribe(res => {
      console.log(res);
      if (res === null) {
        this.loader = false;
        this.message_display = true;
      } else {
        this.loader = false;
        this.message_display = false;
        // console.log(this.allOrdersList);
        var checkNowAll = res[0].orders;
        this.totalOrders = res[0].total_order_count
        this.allOrdersList = checkNowAll.filter(obj => obj.status != "payment_failed");
        this.pagedItems = this.allOrdersList.slice(0);
        if (call === "pageLoad") {
          this.setPage(1);
        }
        // console.log(this.allOrdersList);
      }
    }, err => {
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: err.error.error_desc
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    });
    // }
  }
  countPerPage(count) {
    console.log(count);
    this.shareThisCount = count;
    this.offsetVal = 0;
    this.isFliterActive = false;
    $('#selectall').prop("checked", false);
    this.getAllOrdersList();
    this.setPage(1);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = this.shareThisCount) {
    // this.loader = true;
    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = _.range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    // this.loader = false;
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  setPage(page: number, numClick: string = null) {
    this.ShareThisSelectedList = 0;
    $('#selectall').prop("checked", false);
    // this.loader = true;
    if (page < 1 || page > this.pager.totalPages) {
      this.loader = false;
      return;
    }
    this.offsetVal = (page - 1) * this.shareThisCount;
    // get pager object from service
    if (numClick !== null && this.isFliterActive == false) {
      this.getAllOrdersList();
    }
    this.pager = this.getPager(this.totalOrders, page);
  }

  // ----------------------------------------------//
  onSortClick(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArr("status");
  }

  onSortClick1(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArr1("created_at");
  }
  onSortClickOID(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrOID("order_no");
  }
  onSortClickCN(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrCusN("first_name", "last_name");

    // this.sortArrCN("last_name");

  }
  onSortClickPT(event) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrCN("payment_method");

    // this.sortArrCN("last_name");

  }

  sortArr(colName: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName].toLowerCase();
      b = b[colName].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }

  sortArr1(colName1: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName1].toLowerCase();
      b = b[colName1].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrOID(colName2: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName2].toLowerCase();
      b = b[colName2].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrCN(colName3: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName3].toLowerCase();
      b = b[colName3].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrCusN(colName3: any, x: any) {
    this.pagedItems.sort((a, b) => {
      a = (a[colName3] + ' ' + a[x]).toLowerCase();
      b = (b[colName3] + ' ' + b[x]).toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  orderList
  // --------------------------------------------//
  single(event, data) {
    console.log(data);
    if (this.dict.hasOwnProperty(event)) {
      delete this.dict[event];
    } else {
      this.dict[event] = data;
      console.log(this.dict)
    }
    this.selectedAlldata = [];
    for (var key in this.dict) {
      this.selectedAlldata.push(this.dict[key]);
    }
    this.orderList=this.selectedAlldata
    // let order_ids = [];
    // order_ids = this.orderList.filter((item:any)=>{
    //   return item.order_id;
    // })
    // console.log("order_id",order_ids);

    $('body').on('click', '.singlechkbox', function () {
      if ($('.singlechkbox').length == $('.singlechkbox:checked').length) {
        $('#selectall').prop('checked', true);
        // // console.log("Checkbox is checked.");
      } else {
        $("#selectall").prop('checked', false);
        // // console.log("Checkbox is unchecked.");
      }
    });

    this.howmanySelectedArray = [];
    for (var i = 0; i < this.pagedItems.length; i++) {
      if ($('#dynamicID' + i).prop("checked") === true) {
        this.howmanySelectedArray.push(i);
      }
    }
    console.log(this.howmanySelectedArray.length);
    this.ShareThisSelectedList = this.howmanySelectedArray.length;

  }
  bulkStatus(Index_id, item_s, selected_previous_status) {
    console.log(item_s);
    console.log(this.allOrdersList);
    console.log(this.statusArray);
    console.log(this.statusArray_main_one_userMatrix);
    this.sendThis_item_s = item_s;
    this.sendThis_selected_previous_status = selected_previous_status;

    if (item_s.status_name === "cancelled") {
      this.showOnlyForCancel = true;
    } else {
      this.showOnlyForCancel = false;
    }
  }
  reasonClose() {
    this.showThisMsgReasonRequired = false;
    $('#cancelation_reason').val("");
  }
  bulkStatusUpdate() {
    console.log(this.ShareThisSelectedList);
    $("#bulk_status_confirmation_open").modal("hide");
    if (this.sendThis_item_s.status_name === "placed") {
      var status = "Placed";
    }
    if (this.sendThis_item_s.status_name === "confirmed") {
      var status = "Confirmed";
    }
    if (this.sendThis_item_s.status_name === "pickup_confirmed") {
      var status = "Pickup Confirmed";
    }
    if (this.sendThis_item_s.status_name === "in_process") {
      var status = "In Process";
    }
    if (this.sendThis_item_s.status_name === "ready_for_shipping") {
      var status = "Ready For Shipping";
    }
    if (this.sendThis_item_s.status_name === "out_for_delivery") {
      var status = "Out For Delivery";
    }
    if (this.sendThis_item_s.status_name === "delivered") {
      var status = "Delivered";
    }
    if (this.sendThis_item_s.status_name === "cancelled") {
      var status = "Cancelled";
    }
    console.log(this.sendThis_selected_previous_status);
    // console.log(this.selectedAlldata);
    // console.log(this.pagedItems);
    var orderISsArray = [];
    for (var i = 0; i < this.pagedItems.length; i++) {
      if ($('#dynamicID' + i).prop("checked") === true) {
        if (this.pagedItems[i].status === this.sendThis_selected_previous_status) {
          orderISsArray.push(this.pagedItems[i].order_id);
        }
      }
    }
    console.log(orderISsArray);
    console.log("access", this.statusArray_main_one_userMatrix);
    console.log(this.sendThis_item_s.status_id);
    if (this.sendThis_item_s.status_name === "cancelled") {
      var cancelationReason = $('#cancelation_reason').val();
      if (cancelationReason === "" || cancelationReason === undefined || cancelationReason === null) {
        this.showThisMsgReasonRequired = true;
      } else {
        this.showThisMsgReasonRequired = false;
        console.log("You can");
        var NeworderISsArray = [];
        var NeworderISsArrayNew = [];
        var camparingArray = new Array();
        camparingArray[0] = "cancelled",
          camparingArray[1] = "out_for_delivery",
          camparingArray[2] = "delivered",
          camparingArray[3] = "payment_failed"
        console.log(camparingArray);
        for (var i = 0; i < this.pagedItems.length; i++) {
          if ($('#dynamicID' + i).prop("checked") === true) {
            var n = camparingArray.includes(this.pagedItems[i].status);
            if (n) {
              NeworderISsArrayNew.push(this.pagedItems[i].order_id);
            } else {
              // var NeworderISsArray = [];
              NeworderISsArray.push(this.pagedItems[i].order_id);
            }
          }
        }
        console.log(NeworderISsArray);
        if (NeworderISsArray.length === 0) {
          // $("#bulk_status_confirmation_open").modal("hide");
          $.notify({
            icon: "add_alert",
            message: "You Can't change Into" + " " + status + " " + "Status"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          this.loader = false;
          this.selectedAlldata = [];

          NeworderISsArray = [];
          this.arraychecking = [];
          console.log(this.selectedAlldata);
        } else {
          var cancelationReason = $('#cancelation_reason').val();
          console.log(cancelationReason);
          // if (cancelationReason === "" || cancelationReason === undefined || cancelationReason === null) {
          //   this.showThisMsgReasonRequired = true;
          // } else {
          // this.showThisMsgReasonRequired = false;
          console.log(cancelationReason);
          console.log(NeworderISsArray);
          var reqCancel = {
            "status_id": this.sendThis_item_s.status_id,
            "order_id": NeworderISsArray,
            "reason": cancelationReason
          }
          console.log(reqCancel);
          this.loader = true;
          this.bulk_update_waiting = true;
          this.service.updateBulk(reqCancel).then(async res => {
            console.log(res);
            this.updateMesg = res;
            await this.updateMesg;
            $('#cancelation_reason').val("");
            // $("#bulk_status_confirmation_open").modal("hide");
            $('#selectall').prop("checked", false);
            this.bulk_update_waiting = false;
            this.loader = false;
            $.notify({
              icon: "add_alert",
              message: this.updateMesg.message
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.selectedAlldata = [];

            NeworderISsArray = [];
            this.arraychecking = [];
            console.log(this.selectedAlldata);
            this.showThisMsgReasonRequired = false;
            this.getAllOrdersList();
          }).catch(err => {
            console.log(err);
            this.selectedAlldata = [];
            NeworderISsArray = [];
            console.log(this.selectedAlldata);
            this.loader = false;
            // $("#bulk_status_confirmation_open").modal("hide");
            $('#selectall').prop("checked", false);
            $.notify({
              icon: "add_alert",
              message: err.error.error_desc
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.selectedAlldata = [];
            this.arraychecking = [];
          });
        }
      }
    } else {
      if (orderISsArray.length === 0) {
        $.notify({
          icon: "add_alert",
          message: "You Can't change Into" + " " + status + " " + "Status"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
        this.loader = false;
        this.selectedAlldata = [];

        NeworderISsArray = [];
        this.arraychecking = [];
        console.log(this.selectedAlldata);
        // $("#bulk_status_confirmation_open").modal("hide");
      } else {

        var req = {
          "status_id": this.sendThis_item_s.status_id,
          "order_id": orderISsArray,
          "reason": null
        }
        console.log(req);
        this.loader = true;
        this.bulk_update_waiting = true;
        this.service.updateBulk(req).then(res => {
          console.log(res);
          this.updateMesg = res;
          // await this.updateMesg;
          // $("#bulk_status_confirmation_open").modal("hide");
          $('#selectall').prop("checked", false);
          this.bulk_update_waiting = false;
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: this.updateMesg.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          NeworderISsArray = [];
          this.selectedAlldata = [];
          this.arraychecking = [];
          console.log(this.selectedAlldata);
          this.getAllOrdersList();
        }).catch(err => {
          console.log(err);
          NeworderISsArray = [];
          this.selectedAlldata = [];

          this.arraychecking = [];
          console.log(this.selectedAlldata);
          // $("#bulk_status_confirmation_open").modal("hide");
          $('#selectall').prop("checked", false);
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: err.error.error_desc
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        });

      }
    }
  }

  closethisNow() {
    $("#bulk_status_confirmation_close").trigger("click");
  }
  getStoreCode(){
    this.allStoresCode = [];
      this.service.getAllStoresIds().then(allStores => {
        this.loader = false;
        this.allStoresIds = allStores;
        allStores['all_store_code'].map(e => this.allStoresCode.push(e.store_name));
        console.log("all stores codes", this.allStoresCode);
      }).catch( err => {
        console.log(err);
      });
  }
  wmsAfterResponse(){
    this.getAllOrdersList()
  }
}
