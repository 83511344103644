import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {

  constructor(public http: HttpClient) { }

  login(request) {
    return this.http.post(environment.apiUrl + "oms_api/v1/login", request);
  }

  getAllStores(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/stores"  + "?limit=" + data.limit + "&offset=" + data.offset);
  }
  getAllStoresWD() {
    return this.http.get(environment.apiUrl + "oms_api/v1/stores");
  }

  getSelectedStoreInfo(storeID) {
    return this.http.get(environment.apiUrl + "oms_api/v1/stores/" + storeID);
  }

  createNewStoreAPI(Request) {
    // console.log(Request);
    return this.http.post(environment.apiUrl + "oms_api/v1/stores", Request);
  }

  updateSelecteStore(res, body) {
    // console.log(res);
    // console.log(body);
    return this.http.put(environment.apiUrl + "oms_api/v1/stores/" + res, body);
  }

  updateTheAddressOfSelectedStore(res, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/addresses/" + res, body);
  }
  deleteTheSelectedStore(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/stores/" + id);
  }

  getListOfOrders() {
    return this.http.get(environment.apiUrl + "oms_api/v1/orders");
  }

  getOrdersByID(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles/" + data.user_id + "/orders" + "?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=" + data.is_payment_failed);
  }
  getAllPaymentFailedOrders(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles/" + data.user_id + "/transactions/failed" + "?limit=" + data.limit + "&offset=" + data.offset);
  }

  getOrdersForCRM(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/crm_orders", body);
  }
  getAselectedOrderDetails(orderID) {
    return this.http.get(environment.apiUrl + "oms_api/v1/orders/" + orderID);
  }
  getAselectedOrderDetailsPf(payment_id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/transactions/failed/" + payment_id);
  }

  getAllOrderStatus() {
    return this.http.get(environment.apiUrl + "oms_api/v1/statuses");
  }
  getRefundReasons() {
    return this.http.get(environment.apiUrl + "oms_api/v1/reasons");
  }
  updateTheOrderStatus(id, status_id) {
    return this.http.put(environment.apiUrl + "oms_api/v1/orders/" + id, status_id);
  }

  getAllCustomerInfo(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/customers" + "?limit=" + data.limit + "&offset=" + data.offset);
  }

  getSpesificCustomerInfo(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/customers/" + id);
  }
  sodexoRefund(body){
    return this.http.post(environment.apiUrl + "oms_api/v1/sodexo/refund",body);
  }

  getAllCartInfo(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/user_carts" + "?limit=" + data.limit + "&offset=" + data.offset);
  }
  getSelectedCartInfo(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/carts/" + id);
  }

  createSlotsForStore(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/slots", body);
  }

  getSelectedStoreSlots(res) {
    return this.http.get(environment.apiUrl + "oms_api/v1/stores/" + res + "/slots");
  }

  createFrequency(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/frequency", body);
  }

  getAllFrequency() {
    return this.http.get(environment.apiUrl + "oms_api/v1/frequency");
  }

  selectedFrequencyDetails(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/frequency/" + id);
  }

  updateTheFrequency(id, body) {
    // console.log(id);
    return this.http.put(environment.apiUrl + "oms_api/v1/frequency/" + id, body);
  }
  deleteTheselectedFrequency(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/frequency/" + id);
  }

  createDeliveryOptions(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/delivery_option", body);
  }

  getAllDeliveryOptions() {
    return this.http.get(environment.apiUrl + "oms_api/v1/delivery_option");
  }

  selectedDeliveryOptionDetails(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/delivery_option/" + id);
  }

  updateTheDeliveryOptions(id, body) {
    // console.log(id);
    return this.http.put(environment.apiUrl + "oms_api/v1/delivery_option/" + id, body);
  }

  createNewSubscription_typeAPI(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/delivery_option", body);
  }

  getAllRoles() {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles");
  }

  adminCreateNewUser(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/oms_users", body);
  }

  getALLuserInformation(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/oms_users" + "?limit=" + data.limit + "&offset=" + data.offset);
  }
  getSlectedUserDetails(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/oms_users/" + id);
  }

  updateTheStoreSlots(body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/slots", body);
  }

  updateTheSelectedUser(id, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/oms_users/" + id, body);
  }

  deleteTheSelectedUser(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/oms_users/" + id);
  }

  adminCreateNewRole(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/roles", body);
  }
  getListOfRoles(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles" + "?limit=" + data.limit + "&offset=" + data.offset);
  }

  deleteTheSelectedRole(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/roles/" + id);
  }
  getTheSelectedRoleInformation(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles/" + id);
  }

  updatetheSelectedRole(id, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/roles/" + id, body);
  }

  deleteTheselectedDeliveryOption(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/delivery_option/" + id);
  }

  getAllSubscriptionDetails() {
    return this.http.get(environment.apiUrl + "oms_api/v1/subscriptions");
  }

  deleteTheselectedSubscriptionSerivece(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/subscriptions/" + id);
  }

  createNewSubscription_service(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/subscriptions", body);
  }

  getSelectedSubscriptionAPI(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/subscriptions/" + id);
  }

  updateTheselectedSubscription(id, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/subscriptions/" + id, body);
  }

  getAllRatingsAndReviews(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/orders/rating_and_reviews" + "?limit=" + data.limit + "&offset=" + data.offset);
  }

  getAllScreensAPI() {
    return this.http.get(environment.apiUrl + "oms_api/v1/screens");
  }

  createNewPermission(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/permissions", body);
  }

  selectedUserPermissions(role_id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles/" + role_id + "/permissions");
  }
  updateTheSelectedUserPerMissions(body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/permissions", body);
  }

  downloadCustomeorders(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/orders/filter", body);
  }

  downloadCustomCustomers(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/customers/filter", body);
  }
  downloadCustomeStores(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/stores/filter", body);
  }
  downloadCustomeCart(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/user_carts/filter", body);
  }
  downloadCustomeRatingAndReview(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/orders/rating_and_reviews/filter", body);
  }
  downloadCustomeOMSroles(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/roles/filter", body);
  }
  downloadCustomOMSUsers(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/oms_users/filter", body);
  }

  searchAPI(body) {
    if(body.payment_status){
      return this.http.post(environment.apiUrl + "oms_api/v1/search"+ "?limit=" + body.limit + "&offset=" + body.offset + "&is_payment_failed=" + body.payment_status, body.reqBody);
    } else{
      return this.http.post(environment.apiUrl + "oms_api/v1/search"+ "?limit=" + body.limit + "&offset=" + body.offset , body.reqBody);
    }
  }

  walte(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/wallets/transactions", body);
  }

  dashboardAPI(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/dashboard", body);
  }
  getDashboardAPI() {
    return this.http.get(environment.apiUrl + "oms_api/v1/dashboard");
  }
  createDeliveryCharges(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/delivery_charges", body);
  }
  getDeliveryCharges(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/delivery_charges?store_code=" + id);
  }

  getMinimumOrderValues(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/min_orders_value?store_code=" + id);
  }

  getPackagingValue(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/packaging_types?store_code=" + id);
  }


  deleteTheDeliveryCharge(Id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/delivery_charges/" + Id);
  }
  async updateBulk(body) {
    return await this.http.put(environment.apiUrl + "oms_api/v1/orders/update", body).toPromise();
  }
  financeReportDownload(body) {
    // return this.http.post(environment.apiUrl + "oms_api_reports/v1/finance_filter", body, {responseType: "blob"});//Prod
    return this.http.post(environment.apiUrl + "oms_api/v1/finance_filter", body, {responseType: "blob"});//Dev
  }
  transactionReportDownload(body) {
    // return this.http.post(environment.apiUrl + "oms_api_reports/v1/transaction_filter", body, {responseType: "blob"});//Prod
    return this.http.post(environment.apiUrl + "oms_api/v1/transaction_filter", body, {responseType: "blob"});//Dev

  }
  walletReportDownload(body) {
    // return this.http.post(environment.apiUrl + "oms_api_reports/v1/wallet_filter", body, {responseType: "blob"});//Prod
    return this.http.post(environment.apiUrl + "oms_api/v1/wallet_filter", body, {responseType: "blob"});//Dev

  }
  downloadNewReports(body) {
    // return this.http.post(environment.apiUrl + "oms_api_reports/v1/reports", body, {responseType: "blob"});//Prod
    return this.http.post(environment.apiUrl + "oms_api/v1/reports", body, {responseType: "blob"});//Dev

  }

  GetCustomerDetails(body) {
    return this.http.post(environment.magentoAPIURL + "rest/V1/customapi/getcustomer", body);
  }

  productSearch(body) {
    return this.http.post(environment.magentoAPIURL + "rest/V1/customapi/search", body);
  }


  // addNewAddress(body) {
  //   console.log(localStorage.getItem('token'));
  //   const header = new HttpHeaders().set('Authorization', localStorage.getItem('token'));
  //   return this.http.post(environment.apiUrl + "oms_api/v1/addresses", body, { headers: header });
  // }

  addNewAddress(body) {
    console.log(localStorage.getItem('token'));
    const header = new HttpHeaders().set('Authorization', localStorage.getItem('token'));
    return this.http.post(environment.apiUrl + "oms_api/v1/addresses", body, { headers: header });
  }

  deleteAddress(id) {
    console.log(localStorage.getItem('token'));
    const header = new HttpHeaders().set('Authorization', localStorage.getItem('token'));
    return this.http.delete(environment.apiUrl + "oms_api/v1/addresses/" + id, { headers: header });
  }

  getCustomerAddress() {
    console.log(localStorage.getItem('token'));
    const header = new HttpHeaders().set('Authorization', localStorage.getItem('token'));
    return this.http.get(environment.apiUrl + "oms_api/v1/addresses", { headers: header });
  }
  updateCustAddress(id, body) {
    console.log(localStorage.getItem('token'));
    const header = new HttpHeaders().set('Authorization', localStorage.getItem('token'));
    return this.http.put(environment.apiUrl + "oms_api/v1/addresses/" + id, body, { headers: header });
  }

  addtoCart(body) {
    console.log(localStorage.getItem('token'));
    const header = new HttpHeaders().set('Authorization', localStorage.getItem('token'));
    return this.http.put(environment.apiUrl + "oms_api/v1/carts", body, { headers: header });
  }

  getCartDetails() {
    console.log(localStorage.getItem('token'));
    const header = new HttpHeaders().set('Authorization', localStorage.getItem('token'));
    return this.http.get(environment.apiUrl + "oms_api/v1/carts", { headers: header });
  }

  deleteCartProduct(data) {
    const header = new HttpHeaders().set('Authorization', localStorage.getItem("token"));
    return this.http.delete(environment.apiUrl + "oms_api/v1/carts/items/" + data, { headers: header });
  }

  deleteCart() {
    const header = new HttpHeaders().set('Authorization', localStorage.getItem("token"));
    return this.http.delete(environment.apiUrl + "oms_api/v1/carts/items", { headers: header });

  }

  getPackagingTypes(query) {
    return this.http.get(environment.apiUrl + "oms_api/v1/packaging_types",{params:query});
  }
  updatePrice(data){
    return this.http.put(environment.apiUrl+ "oms_api/v1/carts/prices",data);
  }

  updatePackagingType(data) {
    const header = new HttpHeaders().set('Authorization', localStorage.getItem("token"));
    return this.http.put<any>(environment.apiUrl + "oms_api/v1/carts/packaging_types", data, { headers: header });
  }


  createminOrderVal(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/min_orders_value", body);
  }

  getMinimumOrderValueInfo() {

    return this.http.get(environment.apiUrl + "oms_api/v1/min_orders_value");

  }
  updateMiniOrderval( body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/min_orders_value", body);
  }
  createPackagingType(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/packaging_types", body);
  }

  getPackagingType() {
    return this.http.get(environment.apiUrl + "oms_api/v1/packaging_types");
  }

  editPackagingType(id) {
    return this.http.put(environment.apiUrl + "oms_api/v1/packaging_types",id);
  }

  deletePackagingType(id) {
    console.log(id);
    return this.http.delete(environment.apiUrl + "oms_api/v1/packaging_types/" + id);
  }

  placeAnOrder(body) {
    const header = new HttpHeaders().set('Authorization', localStorage.getItem("token"));
    return this.http.post(environment.apiUrl + "oms_api/v1/payments", body, { headers: header });
  }

  getPaymentStatus(data) {
    const header = new HttpHeaders().set('Authorization', localStorage.getItem("token"));
    return this.http.get(environment.apiUrl + 'oms_api/v1/payments/' + data, { headers: header });
  }

  userCreationGetOTP(body) {
    return this.http.post(environment.magentoAPIURL + "rest/V1/customapi/getotp", body);
  }
  userCreationVerifyOTP(body) {
    return this.http.post(environment.magentoAPIURL + "rest/V1/customapi/registration", body);
  }

  removeItems_from_order(orderID, itemID, msg) {
    // var header = new HttpHeaders();
    // let header = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Access-Control-Allow-Origin': '*'

    // })
    // header.set('Access-Control-Max-Age', '8600')
    // header.set('Content-Type', 'application/x-www-form-urlencoded')

    // const header = new HttpHeaders().set( 'Content-Type', 'application/x-www-form-urlencoded')
    // let reason = 'reason';
    // let requestBody = '  reason=${reason}'
    var form_data = new FormData();
    for ( var key in msg ) {
        form_data.append(key, msg[key]);
    }
    // console.log(form_data)
    return this.http.post(environment.apiUrl + "oms_api/v1/orders/" + orderID + "/items/remove/" + itemID, form_data);
  }
  
  updateTheOrderByOMS(id, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/orders/" + id + "/items", body);
  }

  OmsServiceblityCheck(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/serviceability", body);
  }

  public getSlots() {
    // return this.http.get(environment.apiUrl + 'oms_api/v1/stores/' + localStorage.getItem("storeId") + '/slots');
    return this.http.get<any>(environment.apiUrl + 'oms_api/v1/stores/' + localStorage.getItem('plant_code') + '/tms_slots');
  }

  updaeTimeSlot(requestBody,id) {
    return this.http.put(environment.apiUrl + `oms_api/v1/orders/${id}/edit_slot`, requestBody);
  }

  getAllCoupons() {
    return this.http.get(environment.apiUrl + "oms_api/v1/coupons");
  }

  getCouponByID(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/coupons/" + id);
  }
  createNewCoupan(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/coupons", body);
  }

  updateTheCoupons(id, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/coupons/" + id, body);
  }

  deleteTheSelectedCoupon(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/coupons/" + id);
  }

  moveToSuccess(body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/orders/payment_failed/update", body);
  }

  getInvoiceData(ID) {
    return this.http.get(environment.apiUrl + "oms_api/v1/orders/" + ID + "/bill_printing");
  }

  // Configuration Contacts

  getContactDetails(){
   return this.http.get(environment.apiUrl + "oms_api/v1/contact_details")
  }
  updateContactDetails(reqBody){
   return this.http.put(environment.apiUrl + "oms_api/v1/contact_details",reqBody)

  }

  // store-wise PaymentMethods

  async getAllStoresIds() {
    return this.http.get(environment.apiUrl + "oms_api/v1/stores/store_code").toPromise();
  }
  getPaymentStoreWise(storeCode) {
    return this.http.get(environment.apiUrl + "oms_api/v1/payment_methods?store_code=" + storeCode);
  }
  updateStorepaymentMethods(reqBody){
    return this.http.put(environment.apiUrl + "oms_api/v1/payment_methods/update", reqBody);
  }
 
  //Get WMS Order lists 
  getAllWmsOrders(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles/" + data.user_id + "/orders/wms" + "?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=" + data.is_payment_failed);
  }

  downloadCustomeWmsorders(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/orders/wms_sales_order", body);
  }

  productRemovel(body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/orders/remove_bulk_products", body);
  }

  walletRevasal(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/wallets/refund", body);
  }

  getAllAddress() {
    return this.http.get(environment.apiUrl + "oms_api/v1/orders");
  }


  getAdressById(id) {
    return this.http.get(environment.apiUrl + `oms_api/v1/orders/${id}/order_addresses`);
  }
  updateAddress(requestBody,id) {
    return this.http.put(environment.apiUrl + `oms_api/v1/orders/${id}/order_addresses`, requestBody);
  }
  bulkProdukPrint(requestBody) {
    return this.http.post(environment.apiUrl + 'oms_api/v1/bulk_pdf', requestBody,{responseType: 'text' });
  }
  getNotifications(){
    return this.http.get(environment.apiUrl + 'oms_api/v1/notifications')
  }
  getNotificationsID(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/notifications/" + id);
  }
  deleteNotifications(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/notifications/" + id);
  }
  updateNotifications(id, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/notifications/"+ id, body);
  }
  postNotifications(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/notifications", body);
  }
  getSmsTemplate(){
    return this.http.get(environment.apiUrl + 'oms_api/v1/sms_template')
  }
  getSmsTemplateID(id){
    return this.http.get(environment.apiUrl + 'oms_api/v1/sms_template/'+id)
  }
  updateSmsTemplate(id, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/sms_template/"+ id, body);
  }
  getSmsTriggerOn(){
    return this.http.get(environment.apiUrl + 'oms_api/v1/get_sms_email_references')
  }
  deleteSmsTemplate(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/sms_template/" + id);
  }
  createSmsTemplate(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/sms_template", body);
  }
  getRatedAndReviewID(id){
    return this.http.get(environment.apiUrl + `oms_api/v1/orders/${id}/rating_and_reviews`);
  }
  /*************sms variable ****** */
  getSmsVariable(){
    return this.http.get(environment.apiUrl + 'oms_api/v1/sms/input_variables')
  }
  // searchApi2(body){
  //   return this.http.post(environment.apiUrl + "oms_api/v1/search"+ "?limit=" + body.limit + "&offset=" + body.offset , body.reqBody);
  // }
  // public cartCheck(){
  //   var data = {
  //     "cart_id": localStorage.getItem('cartId'),
  //     "pincode": localStorage.getItem('serviceablePincode')
  //   }
  //   return this.http.put(environment.apiUrl + '/oms_api/v1/carts/prices', data);
  // }

  /*****************Buzzer Sound****************** */
  buzzer(body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/buzzer_status", body);
  }
  // // getbuzzer(storeCode) {
  // //   return this.http.get(environment.apiUrl + "oms_api/v1/buzzer_status?store_code=" + storeCode);
  // // }
  getbuzzer() {
    return this.http.get(environment.apiUrl + "oms_api/v1/all_buzzer_status");
  }

  // /**billdesk refond ******** */
  // billdeskRefund(body) {
  //   return this.http.post(environment.apiUrl + "oms_api/v1/billdesk/refund", body);
  // }
}
